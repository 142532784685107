export const SeedToken = {
  fontSize: 12,
  color: '#111111'
};

export const components = {
  Menu: {
    fontSize: 12,
    borderRadius: 2
  },
  Button: {
    fontSize: 12,
    borderRadius: 2,
    colorPrimary: '#676cc4'
  },
  Breadcrumb: {
    colorText: '#707E92'
  },
  Modal: {
    colorTextHeading: '#111111',
    colorText: '#111111'
  },
  Select: {
    colorBgContainer: '#f9fafc',
    colorBorder: '#F5F6F9',
    colorTextPlaceholder: '#AEBBCE',
    colorTextDescription: 'AEBBCE',
    borderRadius: 1,
    borderRadiusLG: 1
  },
  Input: {
    colorBgContainer: '#f9fafc',
    colorBorder: '#F5F6F9',
    borderRadius: 1,
    borderRadiusLG: 1,
    colorTextPlaceholder: '#AEBBCE',
    colorTextDescription: 'AEBBCE',
    colorBgContainerDisabled: '#EBF0F5'
  },
  DatePicker: {
    colorBgContainer: '#f9fafc',
    colorBorder: '#F5F6F9',
    borderRadius: 1,
    colorTextPlaceholder: '#AEBBCE',
    colorTextDescription: 'AEBBCE',
    colorBgContainerDisabled: '#EBF0F5'
  },
  Form: {
    colorText: '#707E92'
  },
  Table: {
    fontSize: 12,
    colorTextHeading: '#111111',
    fontWeightStrong: 400
  },
  Pagination: {
    colorBgTextActive: '#E1E2F3',
    colorBgTextHover: '#E1E2F3',
    colorText: '#111111'
  },
  Tree: {
    colorBgContainer: '#f9fafc'
  }
};
