const windowPath = import.meta.env.MODE === 'dev' ? 'solinteg-cloud.com' : window.location.hostname,
  wattsonicPortal = 'wattsonic.cloud', // wattsonic 中国
  // etekPortal = 'etek-monitoring.com', // etek 中国
  // solintegPortal = 'solinteg-cloud.com', // Solinteg
  // metaEssPortal = 'meta-ess.com', // Solinteg版本中性`
  mtecPortal = 'energybutler.mtec-portal.com', // M-TEC
  selfaPortal = 'hybrid.selfa-pv.com', // Selfa
  sheenplusPortal = 'portal2.sheenplus.com', // SheenPlus
  dowellPortal = 'monitor.dowellelectronic.com'; // Dowell

// 设置每个地区的语言
export const i18n = () => {
  if (windowPath.includes(wattsonicPortal)) {
    return [
      {
        key: 'en',
        text: 'English'
      },
      {
        key: 'pl', // 波兰
        text: 'Polski'
      },
      {
        key: 'zh',
        text: '中文'
      },
      {
        key: 'de', // 德语
        text: 'Deutsch'
      },
      {
        key: 'cs', // 捷克语
        text: 'Čeština'
      }
    ];
  }

  if (windowPath.includes(mtecPortal)) {
    return [
      {
        key: 'en',
        text: 'English'
      },
      {
        key: 'de', // 德语
        text: 'Deutsch'
      }
    ];
  }

  if (windowPath.includes(selfaPortal)) {
    return [
      {
        key: 'pl', // 波兰
        text: 'Polski'
      }
    ];
  }

  if (windowPath.includes(sheenplusPortal)) {
    return [
      {
        key: 'en',
        text: 'English'
      },
      {
        key: 'it', // 意大利
        text: 'Italiano'
      },
      {
        key: 'de', // 德语
        text: 'Deutsch'
      }
    ];
  }

  if (windowPath.includes(dowellPortal)) {
    return [
      {
        key: 'en',
        text: 'English'
      },
      {
        key: 'es', // 西班牙
        text: 'Español'
      },
      {
        key: 'pl', // 波兰
        text: 'Polski'
      },
      {
        key: 'zh',
        text: '中文'
      },
      {
        key: 'de', // 德语
        text: 'Deutsch'
      },
      {
        key: 'it', // 意大利
        text: 'Italiano'
      }
    ];
  }

  return [
    {
      key: 'en',
      text: 'English'
    },
    {
      key: 'es', // 西班牙
      text: 'Español'
    },
    // {
    //     key: 'hr', // 克罗地亚
    //     text: 'Hrvatski'
    // },
    {
      key: 'pl', // 波兰
      text: 'Polski'
    },
    {
      key: 'pt', // 葡萄牙
      text: 'Português'
    },
    // {
    //     key: 'vi', // 越南
    //     text: 'Tiếng Việt'
    // },
    {
      key: 'zh',
      text: '中文'
    },
    {
      key: 'de', // 德语
      text: 'Deutsch'
    },
    {
      key: 'it', // 意大利
      text: 'Italiano'
    },
    {
      key: 'cs', // 捷克语
      text: 'Čeština'
    }
  ];
};

/**
 *
 * //其余配置
 * const otherSetting = () => {
 *     if (windowPath.includes(wattsonicPortal)) {
 *         // ...
 *     }
 *     if (windowPath.includes(etekPortal)) {
 *         // ...
 *     }
 *     if (windowPath.includes(solintegPortal)) {
 *         // ...
 *     }
 *     if (windowPath.includes(metaEssPortal)) {
 *         // ...
 *     }
 *     if (windowPath.includes(mtecPortal)) {
 *         // ...
 *     }
 *     if (windowPath.includes(selfaPortal)) {
 *         // ...
 *     }
 *     if (windowPath.includes(sheenplusPortal)) {
 *         // ...
 *     }
 *     if (windowPath.includes(dowellPortal)) {
 *         // ...
 *     }
 * };
 *
 *
 */

// 每个odm定制的主题色
export const webSiteColors = () => {
  if (windowPath.includes(wattsonicPortal)) {
    return '#676CC4';
  }
  // if (windowPath.includes(etekPortal)) {
  //     // return '#fe5000';
  //     return '#DE572D';
  // }
  // if (windowPath.includes(metaEssPortal)) {
  //     return '#3582FA';
  // }
  if (windowPath.includes(mtecPortal)) {
    // return '#f7a824';
    return '#EEA42B';
  }
  if (windowPath.includes(selfaPortal)) {
    return '#0E6A38';
  }
  if (windowPath.includes(sheenplusPortal)) {
    return '#F15C24';
  }
  // if (windowPath.includes(solintegPortal)) {
  //     return '#5046BB';
  // }
  if (windowPath.includes(dowellPortal)) {
    // return '#e38132';
    return '#F18B29';
  }
  // 默认使用solinteg
  return '#676CC4';
};
