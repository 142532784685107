import React from 'react';
import { Spin } from 'antd';
import './style.less';

class Loading extends React.Component {
    componentDidMount() {
        // console.log('组件：loading...');
    }
    render() {
        return (
            <div className="loading_example">
                <Spin size="large" />
            </div>
        );
    }
}

export default Loading;
