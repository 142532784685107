import { createGlobalStore } from 'hox';
import { useState } from 'react';
import { webSiteColors } from '@admin/utils/customize';

/**
 * 第一个元素是用来订阅 store 的 Hook 函数
 * 第二个元素是一个静态获取函数  可以在任何地方使用(js/ts/jsx/tsx)
 * TODO: 使用方法  const {themeParams} = getAppStore();
 */
export const [useAppStore, getAppStore] = createGlobalStore(() => {
  const [themeParams, setThemeParams] = useState({
      colorPrimary: webSiteColors()
    }),
    themeConfig = color => {
      setThemeParams(Object.assign({}, themeParams));
    };

  return {
    themeParams,
    themeConfig
  };
});
