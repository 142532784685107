import { Suspense } from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import Loading from './components/common/Loading';
import { useAppStore } from './store/app';
import { useEffect } from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { SeedToken, components } from './theme';
import { routerRoot } from './router';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';

const generateRoutes = route => {
    return route.map(item => {
        return {
            ...item,
            loader: item.loader ? item.loader : () => item,
            children: item?.children && generateRoutes(item.children)
        };
    });
};

const resizeListener = () => {
    const designSize = 1280;
    const html = document.documentElement;
    const clientW = html.clientWidth;
    const htmlRem = (clientW * 10) / designSize;
    html.style.fontSize = htmlRem + 'px';
};

const routes = generateRoutes(routerRoot);

function App() {
    const { themeParams } = useAppStore();

    const router = createHashRouter(routes);
    // const location = useLocation();
    // const { pathname } = location;
    // const navigate = useNavigate();
    // const token = store.get('token');

    useEffect(() => {
        resizeListener();
        window.addEventListener('resize', resizeListener);
    }, []);

    // useEffect(() => {
    //   if (pathname === '/') {
    //     navigate('/login');
    //   }

    //   if (!noLogin.includes(pathname) && !token) {
    //     navigate('/login');
    //   }
    // }, [pathname]);

    return (
        <Suspense fallback={<Loading />}>
            <ConfigProvider
                locale={zhCN}
                theme={{
                    token: {
                        ...themeParams,
                        ...SeedToken
                    },
                    components
                }}>
                <StyleProvider hashPriority="high">
                    <RouterProvider router={router} />
                </StyleProvider>
            </ConfigProvider>
        </Suspense>
    );
}

export default App;
