import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import zh from './zh/translation.json';
import en from './en/translation.json';

export const i18nInit = () => {
  i18next.use(initReactI18next)
    .init({
      lng: 'zh',
      resources: {
        en: { translation: en },
        zh: { translation: zh }
      },
      interpolation: {
        escapeValue: false
      }
    });
};
