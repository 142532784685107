import ReactDOM from 'react-dom/client';
import App from './App';
import { HoxRoot } from 'hox';
import './index.css';
import { i18nInit } from './locales/i18n';

i18nInit();

const rootDom = ReactDOM.createRoot(document.getElementById('root'));

rootDom.render(
  <HoxRoot>
    <App />
  </HoxRoot>
);
